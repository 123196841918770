<template>
  <div>
    <b-list-group>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Durum
        </div>
        <div class="text-danger">
          {{ dataItem.support_status }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Oluşturan
        </div>
        <div class="text-warning">
          {{ dataItem.customer_user }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Önem Derecesi
        </div>
        <div class="text-muted">
          {{ dataItem.priority }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Konu
        </div>
        <div class="text-muted">
          {{ dataItem.subject }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary font-weight-bold">
          Talep
        </div>
        <div class="text-muted">
          {{ dataItem.text }}
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="dataItem.files.length">
        <div class="text-primary font-weight-bold">
          Dosya Eki
        </div>
        <div
          v-for="file in dataItem.files"
          :key="file.id"
          class="text-primary font-small-2 d-inline-flex"
        >
          <b-link
            :href="mediaUrl + '/media/support_files/' +file.filename"
            target="_blank"
            class="mr-1"
          >
            <FeatherIcon icon="PaperclipIcon" />
            Dosya Eki
          </b-link>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem, BLink } from 'bootstrap-vue'

export default {
  name: 'SupportSummary',
  components: {
    BListGroup, BListGroupItem, BLink,
  },
  data() {
    return {
      mediaUrl: this.$store.state.app.baseURL,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['clientSupports/dataItem']
    },
  },
}
</script>
