<template>
  <div>
    <b-alert
      class="mt-2"
      show
      variant="danger"
    >
      <div class="alert-body text-center lead">
        <FeatherIcon icon="InfoIcon" />
        Destek talebi onayınızı bekliyor.
      </div>
    </b-alert>
    <div class="text-center">
      <b-button
        variant="success"
        class="mr-1"
        @click="changeStatus(2)"
      >
        <FeatherIcon icon="CheckIcon" />
        Onayla
      </b-button>
      <b-button
        variant="danger"
        class="ml-1"
        @click="changeStatus(6)"
      >
        <FeatherIcon icon="XIcon" />
        Reddet
      </b-button>
    </div>
  </div>
</template>

<script>
import { BAlert, BButton } from 'bootstrap-vue'

export default {
  name: 'ApprovedButtons',
  components: {
    BAlert,
    BButton,
  },
  props: {
    itemId: {
      type: String,
      required: true,
    },
    updateData: {
      type: Function,
      required: true,
    },
  },
  methods: {
    changeStatus(id) {
      this.$store.dispatch('clientSupports/approved', {
        id: this.itemId,
        id_support_statuses: id,
        approved: this.moment().format('YYYY-DD-MM HH:mm:ss'),
      })
      this.updateData()
    },
  },
}
</script>
