<template>
  <b-list-group>
    <b-list-group-item
      v-for="line in lines"
      :key="line.id"
    >
      {{ line.text }}
      <div class="font-small-2 text-muted">
        <div :class="line.customer_user? 'text-warning' : 'text-primary'">
          {{ line.customer_user? line.customer_user : line.username }}
        </div>
        <div>
          {{ moment(line.created).format('LLLL') }}
        </div>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>
<script>
import { BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'SupportLines',
  components: {
    BListGroup, BListGroupItem,
  },
  props: {
    lines: {
      type: Array,
      required: true,
    },
  },
}
</script>
