<template>
  <b-row>
    <b-col>
      <b-card title="Talep">
        <support-summary />
        <template v-if="Number(userData.id) === Number(dataItem.id_customer_users)">
          <div
            v-if="['1','2'].includes(dataItem.id_support_statuses)"
            class="text-center"
          >
            <b-button
              class="mt-2"
              variant="primary"
              size="sm"
              @click="closeTicket"
            >
              <FeatherIcon icon="XIcon" />
              Talebi Sonlandır
            </b-button>
          </div>
        </template>
        <approved-buttons
          v-if="Number(userData.id) === Number(dataItem.id_approval) && dataItem.id_support_statuses === '1'"
          :item-id="dataItem.id"
          :update-data="getData"
        />
      </b-card>
    </b-col>
    <b-col v-if="['2','3'].includes(dataItem.id_support_statuses) && Number(userData.id) === Number(dataItem.id_customer_users)">
      <b-card title="Talep Yanıtları">
        <div
          v-if="dataItem.lines.length > 0"
          class="mb-1"
        >
          <support-lines :lines="dataItem.lines" />
        </div>
        <div>
          <validation-observer ref="simpleRules">
            <support-answer />
            <b-button
              variant="danger"
              @click="submitAnswer"
            >
              <FeatherIcon icon="SendIcon" />
              Gönder
            </b-button>
          </validation-observer>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import SupportAnswer from '@/views/Client/Supports/elements/SupportAnswer.vue'
import SupportSummary from '@/views/Client/Supports/View/SupportSummary.vue'
import SupportLines from '@/views/Client/Supports/View/SupportLines.vue'
import ApprovedButtons from '@/views/Client/Supports/View/ApprovedButtons.vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Add',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    SupportSummary,
    SupportAnswer,
    SupportLines,
    ApprovedButtons,
    ValidationObserver,
  },
  data() {
    return {
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['clientSupports/dataItem']
    },
    answer() {
      return this.$store.getters['clientSupportLines/dataItem']
    },
    saveData() {
      return this.$store.getters['clientSupportLines/dataSaveStatus']
    },
    saveDataSupport() {
      return this.$store.getters['clientSupports/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getData()
        this.$store.commit('clientSupportLines/RESET_DATA_ITEM')
        this.$refs.simpleRules.reset()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
    saveDataSupport(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getData()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('clientSupports/getDataItem', this.$route.params.id)
    },
    closeTicket() {
      this.$store.dispatch('clientSupports/saveData', {
        id: this.$route.params.id,
        id_support_statuses: '7',
      })
    },
    submitAnswer() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.answer.id_supports = this.$route.params.id
          this.$store.dispatch('clientSupportLines/saveData', this.answer)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
